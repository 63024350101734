<template>
  <a-layout style="padding: 0px; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>招商规划</a-breadcrumb-item>
            <a-breadcrumb-item>方案管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visibles == false" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select style="width: 90px" v-model="value" default-value="name">
              <a-select-option value="name"> 方案名称 </a-select-option>
              <a-select-option value="state"> 状态 </a-select-option>
            </a-select>
            <a-select
              v-if="value == 'state'"
              @change="onSearch"
              style="width: 210px"
            >
              <a-select-option value="0"> 启用 </a-select-option>
              <a-select-option value="1"> 禁用 </a-select-option>
            </a-select>
            <a-input-search
              v-else
              placeholder="请输入搜索内容"
              style="width: 210px"
              v-model="key"
              @search="onSearch"
            />
          </a-input-group>
          <a-button
            type="primary"
            style="margin: 0 15px"
            @click.prevent="showDrawer"
          >
            新增方案
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; overflow: auto">
      <a-layout-content
        v-if="!visibles"
        :style="{ background: '#fff', padding: '24px', margin: 0 }"
      >
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="Planlist"
          >
            <span slot="time" slot-scope="text">{{ text | time }}</span>
            <span slot="state" slot-scope="text">
              <a-badge
                :status="text | statusTypeFilter"
                :text="text | statusFilter"
              />
            </span>
            <a slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">编辑</a> &nbsp;|&nbsp;

              <a @click="toplan(record)">规划</a> &nbsp;|&nbsp;
              <a @click="onchangestate(record)">{{
                record.state == 0 ? ' ' : '启用'
              }}</a></a
            >
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="total"
            @change="onpagesize"
          />
          <!-- 模态编辑 -->
          <a-modal
            :visible="visible"
            okText="确定"
            cancelText="取消"
            :title="title"
            @ok="handleOk"
            @cancel="onClose"
          >
            <a-form-model
              ref="ruleForm"
              :rules="rules"
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-item prop="projectId" label="项目名称">
                <a-select
                  style="width: 100%"
                  @change="properyChange"
                  placeholder="请选择项目"
                  disabled="disabled"
                  v-model="form.projectId"
                >
                  <a-select-option
                    v-for="item in projectlist"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.shortName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-model-item ref="name" prop="name" label="方案名称">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.name"
                />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
        </div>
      </a-layout-content>
      <a-layout-content
        v-else
        style="padding: 24px; margin: 0; background-color: #fff; overflow: auto"
      >
        <div class="search-wrap display-f-row">
          <div class="search-wrop-left">
            <div class="title-guihua">铺位选择</div>
            <div style="height: 600px" class="display-f-row">
              <div class="search-left-project">
                <div class="search-left-top">项目</div>
                <div style="margin-top: 10px">
                  <div
                    v-for="(t, i) in projectlist"
                    :key="t.id"
                    @click="properyChange(t, i)"
                    :class="isproject == i ? 'back-search' : 'search-bottom'"
                  >
                    {{ t.shortName }}
                  </div>
                </div>
              </div>
              <div class="search-left-build">
                <div class="search-left-top">楼栋</div>
                <div style="margin-top: 10px">
                  <div
                    v-for="(t, i) in buildlist"
                    :key="t.id"
                    @click="buildChange(t, i)"
                    :class="isbuild == i ? 'back-search' : 'search-bottom'"
                  >
                    {{ t.name }}
                  </div>
                </div>
              </div>
              <div class="search-left-floor">
                <div class="search-left-top">楼层</div>
                <div style="margin-top: 10px">
                  <div
                    v-for="(t, i) in floorlist"
                    :key="t.id"
                    @click="floorChange(t, i)"
                    :class="isfloor == i ? 'back-search' : 'search-bottom'"
                  >
                    {{ t.name }}
                  </div>
                </div>
              </div>
              <div class="search-left-stoor">
                <div class="search-left-top">铺位</div>
                <div style="margin-top: 10px">
                  <div
                    v-for="(t, i) in storelist"
                    :key="t.id"
                    @click="storeChange(t, i)"
                    :class="isstore == i ? 'back-search' : 'search-bottom'"
                  >
                    {{ t.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-wrop-right">
            <div class="title-guihua">业态规划</div>
            <div
              style="padding: 0 24px; height: 600px"
              class="display-f-column"
            >
              <a-form-model-item label="业态">
                <a-cascader
                  v-model="forms.businessTypeId"
                  :field-names="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }"
                  style="width: 100%"
                  :options="BusinessTypes"
                  placeholder="请选择业态"
                  change-on-select
                  @change="onChangetype"
                />
              </a-form-model-item>
              <a-form-model-item label="品牌">
                <a-select
                  show-search
                  :value="value"
                  placeholder="品牌不能为空"
                  style="width: 617px"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch"
                  @change="handleChange"
                >
                  <a-select-option v-for="d in datalist" :key="d.value">
                    {{ d.text }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-button @click="onAddpinpai" class="addpinpai">添加</a-button>
              <div class="addtodolist">
                <div style="flex-wrap: wrap" class="display-f-row">
                  <div
                    v-for="(i, index) in Nativlist"
                    :key="i.id"
                    class="list-li display-f-row"
                  >
                    <img
                      class="list-li-img"
                      src="../../../assets/4fce37a73c4a1f5087623fd1fe3f6d01.jpeg"
                      alt=""
                    />
                    <div class="list-div display-f-column">
                      <span style="font-size: 14px; font-weight: bold">{{
                        i.brandName
                      }}</span>
                      <span class="text-out"
                        >需求定位:
                        <span class="text-gray">
                          {{
                            i.priceGrade == 2054
                              ? '高端'
                              : i.priceGrade == 2055
                              ? '中高端'
                              : '大众化'
                          }}</span
                        ></span
                      >
                      <span class="text-out"
                        >需求面积:
                        <span class="text-gray">
                          {{ i.mianJiMin }}-{{ i.mianJiMax }}平米</span
                        ></span
                      >
                      <span class="text-out">已入住776家</span>
                    </div>
                    <span @click="ondeletebrand(index)" class="delete"
                      ><a-icon type="close"
                    /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visibles == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div class="sace-brand">
        <a-button type="primary" @click="onSavebrand"> 提交 </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
let timeout

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  async function fake() {
    try {
      const res = await http.getNativelist(value)
      //   console.log(res.data.data);
      const sult = res.data.data.brand
      const brand = []
      sult.forEach(item => {
        brand.push({
          value: item.id,
          text: item.brandName,
        })
      })
      callback(brand)
    } catch (ex) {
      console.log('error:' + ex)
    }
  }
  timeout = setTimeout(fake, 300)
}

const columns = [
  {
    title: '方案名称',
    dataIndex: 'name',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const statusMap = {
  0: {
    status: 'processing',
    text: '启用',
  },
  1: {
    status: 'error',
    text: '禁用',
  },
}
import http from '../../../http'
export default {
  data() {
    return {
      data: [],
      isproject: 0,
      isbuild: 0,
      isfloor: 0,
      isstore: 0,
      projectlist: [],
      buildlist: [],
      floorlist: [],
      storelist: [],
      columns,
      title: '',
      visible: false,
      visibles: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      BusinessTypes: [],
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      value: 'name',
      key: '',
      Planlist: [],
      Nativlist: [],
      selectedRowKey: [],
      Id: '',
      forms: {
        businessTypeId: [],
      },
      form: {
        id: '',
        name: '',
        planId: '',
        state: '',
        storeId: '',
        projectId: JSON.parse(localStorage.getItem('store-userlist'))
          .projectInfo.projectId,
        businessTypeId: '',
        brands: [],
      },
      rules: {
        projectId: [
          {
            required: true,
            message: '项目名称不能为空',
            trigger: 'blur',
          },
        ],
        planId: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    time(time) {
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (value == 'mianJiMax') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getPlan()
    },
    handleSearch(value) {
      fetch(value, brand => (this.datalist = brand))
    },
    handleChange(value) {
      //   console.log(value);
      this.value = value
      fetch(value, brand => (this.datalist = brand))
    },
    onAddpinpai() {
      this.getNativeId(this.value)
    },
    ondeletebrand(index) {
      //   console.log(index);
      this.Nativlist.splice(index, 1)
    },
    onChangetype(e) {
      //   console.log(e);
      this.form.businessTypeId = e[e.length - 1]
    },
    onSavebrand() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.brands = []
          this.Nativlist.forEach(item => {
            this.form.brands.push({ id: item.id })
          })
          setTimeout(() => {
            this.putDetail(this.form)
          }, 100)
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    toplan(e) {
      this.Id = e.id
      this.form.planId = e.id
      this.visibles = true
      this.getplanstoredetail('1426064427228712961')
    },
    handleOk() {
      //   console.log(this.form);
      this.visible = false
      if (this.Id == '') {
        this.AddPlan(this.form)
        // console.log(this.form, "1");
      } else if (this.Id != '') {
        this.putPlan(this.form)
        // console.log("编辑" + this.form);
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该方案么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deletePlan()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async putDetail(form) {
      try {
        const res = await http.putDetail(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async deletePlan() {
      try {
        const res = await http.deletePlan(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    onClose() {
      this.visible = false
      this.visibles = false
      this.Id = ''
      this.form.id = ''
      this.form.planId = ''
      this.form.state = ''
      this.form.storeId = ''
      this.form.businessTypeId = ''
      this.form.brands = []
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      //   console.log(this.form.id);
      this.visible = true
      this.title = '编辑'
      this.getPlanId(item.id)
    },
    async putPlan(form) {
      try {
        const res = await http.putPlan(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          data.businessTypes.forEach(i => {
            if (i.children.length > 0) {
              i.children.forEach(i => {
                if (i.children.length > 0) {
                  i.children.forEach(i => {
                    if (i.children.length > 0) {
                      i.children.forEach(() => {
                        // console.log(i);
                        this.$message.error('业态底层')
                      })
                    } else {
                      i.children = undefined
                    }
                  })
                } else {
                  i.children = undefined
                }
              })
            } else {
              i.children = undefined
            }
          })
          this.BusinessTypes = data.businessTypes

          console.log(data.businessTypes)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getPlan()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    onchangestate(e) {
      console.log(e)
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.$message.success('操作成功')
      this.StatePlan(e.id, this.form.state)
    },
    async getNativeId(id) {
      try {
        const res = await http.getNativeId(id)
        const { success, data } = res.data
        console.log(data)
        if (success) {
          this.a = 0
          this.Nativlist.forEach(item => {
            if (data.brand.brandName == item.brandName) {
              this.a++
            }
          })
          if (this.a == 0) {
            this.Nativlist.push(data.brand)
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async StatePlan(id, state) {
      try {
        const res = await http.StatePlan(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getPlan() {
      try {
        const res = await http.getPlan(this.params)
        const { success, data } = res.data
        if (success) {
          this.Planlist = data.plans.records
          this.total = data.plans.total
          //   console.log(data.plans);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getPlanId(id) {
      try {
        const res = await http.getPlanId(id)
        console.log(res)
        const { success, data } = res.data
        if (success) {
          this.form.name = data.plan.name
          this.form.state = data.plan.state
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async AddPlan(form) {
      try {
        const res = await http.AddPlan(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    //Change事件
    properyChange(t, i) {
      this.isproject = i
      this.buildingid(t.id)
    },
    buildChange(t, i) {
      this.isbuild = i
      this.floorid(t.id)
    },
    floorChange(t, i) {
      this.isfloor = i
      this.storeid(t.id)
    },
    storeChange(t, i) {
      console.log(t.id)
      this.form.storeId = t.id
      this.getplanstoredetail(t.id)
      this.isstore = i
    },
    async getplanstoredetail(storeId) {
      try {
        const res = await http.getplanstoredetail(this.form.planId, storeId)
        const { success, data } = res.data
        if (success) {
          this.forms.businessTypeId = data.planDetail.businessTypeIds
          this.form.businessTypeId = data.planDetail.businessTypeId
          this.Nativlist = data.planDetail.brands
        } else {
          this.forms.businessTypeId = []
          this.form.businessTypeId = ''
          this.Nativlist = []
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //下拉框
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          this.projectlist = data.projects
          this.buildingid(data.projects[0].id)
          //   console.log(data.projects);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
          if (data.buildings.length == 0) {
            this.floorlist = []
          } else {
            this.floorid(this.buildlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
          if (data.floors.length == 0) {
            this.floorlist = []
          } else {
            this.storeid(this.floorlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storeid(floorId) {
      try {
        const res = await http.storeid(floorId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.storelist = data.stores
          this.form.storeId = data.stores[0].id
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          console.log(data)
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getPlan()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getPlan()
    this.BusinessTypelist()
    this.type()
    this.project()
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          console.log('selectedRowKey:' + this.selectedRowKey)
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 630px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.display-f-row {
  display: flex;
  flex-direction: row;
}
.display-f-column {
  display: flex;
  flex-direction: column;
}
.back-search {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f5f5f5;
}
.search-wrop-left {
  width: 49%;
  height: 650px;
  border: 1px solid #d9d9d9;
  .search-left-project,
  .search-left-build,
  .search-left-floor {
    border-right: 1px solid #d9d9d9;
    min-width: 130px;
    width: 23%;
    height: 100%;
  }
  .search-bottom {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
  }
  .search-bottom:hover {
    background-color: #f5f5f5;
  }
  .search-left-top {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f0f1f4;
  }
  .search-left-stoor {
    flex: 1;
  }
}
::v-deep .ant-cascader-menus ul,
.ant-cascader-menus ol {
  width: 30%;
}
.addpinpai {
  width: 130px;
  position: absolute;
  right: 27px;
  top: 187px;
}
.search-wrop-right {
  position: relative;
  width: 49%;
  min-width: 800px;
  height: 650px;
  border: 1px solid #d9d9d9;
}
.addtodolist {
  border: 1px solid #d9d9d9;
  height: 65%;
  padding: 16px;
  overflow: auto;
  .list-li {
    justify-content: space-around;
    width: 300px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 100px;
    position: relative;
    padding: 10px;
    margin: 0 22px 8px;
    .list-li-img {
      width: 100px;
      height: 100%;
    }
    .text-out {
      font-size: 12px;
    }
    .text-gray {
      color: #a9a9a9;
    }
    .delete {
      position: absolute;
      right: 5px;
      top: 5px;
      display: none;
    }
  }
  .list-li:hover {
    .delete {
      display: block;
    }
  }
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.title-guihua {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
